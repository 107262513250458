import React, { useState } from 'react';
import FormNavigation from 'components/FormNavigation';
import Checkbox from 'components/Checkbox/Checkbox';
import { FlowComponentType } from 'routes/FlowRouter';
import Button from 'components/Button';
import { StudentLoanPslfWontSaveMoneyResult } from 'enums/StudentLoanForgivenessFlowResults';

import QuestionsWrapper from './Wrapper/QuestionsWrapper';

import styles from './Questions.module.scss';

const PslfWontSaveMoneyDetails = ({ navigationInfo, handleNext: onNext }: FlowComponentType) => {
  const [isStudent, setIsStudent] = useState<boolean>(false);
  const [isLeavingNonProfits, setIsLeavingNonProfits] = useState<boolean>(false);

  const handleNext = () => {
    analytics.track(`Student Loan PSLF Won't Save Money Details`, { isStudent, isLeavingNonProfits });
    onNext(isStudent ? StudentLoanPslfWontSaveMoneyResult.IsStudent : StudentLoanPslfWontSaveMoneyResult.Other);
  };
  return (
    <>
      <FormNavigation {...navigationInfo} />
      <QuestionsWrapper>
        <>
          <div className={styles.checkboxList}>
            <p className={styles.label}>Do any of these situations apply to you?</p>
            <Checkbox
              label="I’m currently or was recently a student, so my loans aren’t due yet."
              checked={isStudent}
              onChange={(e) => {
                setIsStudent(e.target.checked);
              }}
            />
            <Checkbox
              label="I plan to leave non-profits before I’d get forgiveness."
              checked={isLeavingNonProfits}
              onChange={(e) => {
                setIsLeavingNonProfits(e.target.checked);
              }}
            />
          </div>
          <Button onClick={handleNext} className={styles.button}>
            Next
          </Button>
        </>
      </QuestionsWrapper>
    </>
  );
};

export default PslfWontSaveMoneyDetails;
