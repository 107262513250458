import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PartnerName } from 'enums/PartnerName';
import { getApplicationData } from 'thunks';

import { GetApplicationDataResponse } from './applicationData';

export enum LoanAmountRange {
  LessThan2000 = 'Less than $2,000',
  Between2000And5000 = '$2,000 - $5,000',
  Between5001And10000 = '$5,001 - $10,000',
  Between10001And15000 = '$10,001 - $15,000',
  Between15001And25000 = '$15,001 - $25,000',
  MoreThan25000 = 'More than $25,000',
}

export enum CreditScoreRange {
  Poor = 'POOR (Less than 580)',
  Fair = 'FAIR (580-650)',
  Average = 'AVERAGE (651-680)',
  Good = 'GOOD (681-720)',
  Excellent = 'EXCELLENT (More than 720)',
}

export interface PreQualificationData {
  borrower_state_or_province?: string;
  loan_amount_range?: LoanAmountRange;
  loan_amount_passed?: boolean;
  credit_score_range?: CreditScoreRange;
  loan_purpose?: string;
  credit_score_passed?: boolean;
  loan_term_in_months?: number;
  partnerName?: PartnerName;
  loan_goal?: string;
}

const initialState: PreQualificationData = {
  loan_amount_range: LoanAmountRange.Between10001And15000,
  credit_score_range: CreditScoreRange.Fair,
  loan_amount_passed: false,
  credit_score_passed: false,
  loan_term_in_months: 36,
};

const preQualificationData = createSlice({
  name: 'preQualification',
  initialState,
  reducers: {
    setStateData: (state: PreQualificationData, { payload }: PayloadAction<string | undefined>) => {
      state.borrower_state_or_province = payload;
      analytics.identify({ borrowerState: payload });
    },
    setLoanAmountRangeData: (state: PreQualificationData, { payload }: PayloadAction<PreQualificationData>) => {
      state.loan_amount_range = payload.loan_amount_range;
      analytics.identify({ loanAmountRange: payload.loan_amount_range });
    },
    setCreditScoreRangeData: (state: PreQualificationData, { payload }: PayloadAction<PreQualificationData>) => {
      state.credit_score_range = payload.credit_score_range;
      analytics.identify({ creditScoreRange: payload.credit_score_range });
    },
    setLoanPurposeData: (state: PreQualificationData, { payload }: PayloadAction<PreQualificationData>) => {
      state.loan_purpose = payload.loan_purpose;
      analytics.identify({ loanPurpose: payload.loan_purpose });
    },
    setPartnerName: (state: PreQualificationData, { payload }: PayloadAction<PreQualificationData>) => {
      state.partnerName = payload.partnerName;
    },
    setGoal: (state: PreQualificationData, { payload }: PayloadAction<string>) => {
      state.loan_goal = payload;
      analytics.identify({ loanGoal: payload });
    },
    setLoanAmountPassed: (state: PreQualificationData, { payload }: PayloadAction<boolean>) => {
      state.loan_amount_passed = payload;
    },
    setCreditScorePassed: (state: PreQualificationData, { payload }: PayloadAction<boolean>) => {
      state.credit_score_passed = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getApplicationData.fulfilled, (state, { payload }: PayloadAction<GetApplicationDataResponse>) => {
      const { partner } = payload.application;
      if (partner) {
        state.partnerName = partner as PartnerName;
      }
    });
  },
});

export const {
  setStateData,
  setLoanAmountRangeData,
  setCreditScoreRangeData,
  setLoanPurposeData,
  setPartnerName,
  setLoanAmountPassed,
  setCreditScorePassed,
  setGoal,
} = preQualificationData.actions;

export default preQualificationData.reducer;
