import React, { useState } from 'react';
import clsx from 'clsx';

import FormNavigation from 'components/FormNavigation';
import { FlowComponentType } from 'routes/FlowRouter';
import { ConversionType, trackConversion } from 'utils/analytics';
import { StudentLoanApplyOptionsResult } from 'enums/StudentLoanForgivenessFlowResults';
import CalendlyModal from 'components/StudentLoanForgiveness/Modal/CalendlyModal';
import FormContainer from 'components/LoanForm/FormContainer';
import Button from 'components/Button';
import { ButtonType } from 'components/Button/Button';
import AreYouSureModal from 'components/StudentLoanForgiveness/Modal/AreYouSureModal';
import { useSelector } from 'react-redux';
import { getPreQualificationData } from 'selectors/preQualificationData';
import { PartnerName } from 'enums/PartnerName';
import { ReactComponent as Contract } from 'images/contract.svg';

import styles from './ApplyOptions.module.scss';

enum Result {
  ApplyWithUs = 'Apply with Us',
  ApplyOnYourOwn = 'Apply on Your Own',
  TalkToAnAdvisor = 'Talk to an Advisor',
}

const ApplyOptions = ({ navigationInfo, handleNext }: FlowComponentType) => {
  const [isScheduleOpen, setScheduleIsOpen] = useState(false);
  const [areYouSureModalOpen, setAreYouSureModalOpen] = useState(false);
  const [selected, setSelected] = useState(Result.ApplyWithUs);
  const { partnerName } = useSelector(getPreQualificationData);

  const onNext = () => {
    analytics.track('Student Loan Apply Option Selected', { selected });

    switch (selected) {
      case Result.TalkToAnAdvisor:
        setAreYouSureModalOpen(false);
        setScheduleIsOpen(true);
        break;
      case Result.ApplyOnYourOwn:
        handleNext(StudentLoanApplyOptionsResult.ApplyOnYourOwn);
        break;
      case Result.ApplyWithUs:
        handleNext(StudentLoanApplyOptionsResult.ApplyWithUs);
        break;
      default:
    }
  };

  const onSchedule = () => {
    trackConversion(ConversionType.BookedStudentLoanMeeting);
    setScheduleIsOpen(false);
    handleNext(StudentLoanApplyOptionsResult.Schedule);
  };

  const handleNotNow = () => {
    analytics.track('Schedule Consultation Not Now Pressed');
    handleNext(StudentLoanApplyOptionsResult.NotNow);
  };

  return (
    <>
      <CalendlyModal open={isScheduleOpen} onClose={() => setScheduleIsOpen(false)} onSchedule={onSchedule} />
      {areYouSureModalOpen && (
        <AreYouSureModal
          onClose={() => setAreYouSureModalOpen(false)}
          onNext={() => setAreYouSureModalOpen(false)}
          onNotNow={handleNotNow}
          nextLabel="Continue Applying"
        />
      )}
      <FormNavigation {...navigationInfo} />
      <FormContainer
        // video="https://player.vimeo.com/video/496744754?loop=1&autoplay=1&showinfo=0&muted=1&controls=0"
        icon={<Contract />}
        title="Let's apply together!"
      >
        <div className={styles.options}>
          <div
            className={clsx(styles.option, { [styles.selected]: selected === Result.ApplyWithUs })}
            onClick={() => setSelected(Result.ApplyWithUs)}
          >
            <p className={styles.heading}>
              Apply with Us
              <span className={styles.chip}>Recommended</span>
            </p>
            <p className={styles.subHeading}>Stress free – we’ll do the work for you.</p>
            <div className={styles.description}>
              <ul>
                <li>An expert will review your information, apply and appeal on your behalf.</li>
                <li>
                  Guaranteed enrollment before Dec 31<sup>st</sup>.
                </li>
              </ul>
            </div>
          </div>
          <div
            className={clsx(styles.option, { [styles.selected]: selected === Result.ApplyOnYourOwn })}
            onClick={() => setSelected(Result.ApplyOnYourOwn)}
          >
            <p className={styles.heading}>Apply on Your Own</p>
            <p className={styles.subHeading}>We’ll point you in the right direction.</p>
            <div className={styles.description}>
              <ul>
                <li>
                  <span className={styles.cautionChip}>Caution</span> 98% of applicants applying on their own have been
                  rejected.
                </li>
                <li>Most applicants do not hear back at all from their servicer or the government.</li>
              </ul>
            </div>
          </div>
          {partnerName === PartnerName.Hillcrest && (
            <div
              className={clsx(styles.option, { [styles.selected]: selected === Result.TalkToAnAdvisor })}
              onClick={() => setSelected(Result.TalkToAnAdvisor)}
            >
              <p className={styles.heading}>Talk to an Advisor</p>
              <p className={styles.description}>
                We offer 30 minute consultations to Hillcrest employees to help you decide.
              </p>
              <p className={clsx(styles.description, styles.warning)}>
                Limited availability through Dec 31<sup>st</sup> due to exceptional demand.
              </p>
            </div>
          )}
        </div>

        <div className={styles.buttonContainer}>
          <Button onClick={onNext}>Next</Button>

          <Button type={ButtonType.Transparent} onClick={() => setAreYouSureModalOpen(true)}>
            Not Now
          </Button>
        </div>
      </FormContainer>
    </>
  );
};

export default ApplyOptions;
