import React from 'react';
import { useSelector } from 'react-redux';
import { getStudentLoanData } from 'selectors/getStudentLoanData';
import FormContainer from 'components/LoanForm/FormContainer';
import { formatMonetaryAmount } from 'utils/formatMonetaryAmount';
import { ReactComponent as StudentLoanIcon } from 'images/student-loan.svg';

const QuestionsWrapper = ({ children }: { children: JSX.Element }) => {
  const { totalBalance } = useSelector(getStudentLoanData);

  return (
    <>
      <FormContainer
        icon={<StudentLoanIcon />}
        title="You May Qualify for Student&nbsp;Loan&nbsp;Forgiveness"
        subtitle={
          <>
            You have <strong>{formatMonetaryAmount(totalBalance)}</strong> in federal student loans.
          </>
        }
      >
        {children}
      </FormContainer>
    </>
  );
};

export default QuestionsWrapper;
