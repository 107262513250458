import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Chart, AxisOptions } from 'react-charts';
import clsx from 'clsx';

import FormNavigation from 'components/FormNavigation';
import { FlowComponentType } from 'routes/FlowRouter';
import { StudentLoanBookAppointmentResult } from 'enums/StudentLoanForgivenessFlowResults';
import FormContainer from 'components/LoanForm/FormContainer';
import Button from 'components/Button';
import { ButtonType } from 'components/Button/Button';
import AreYouSureModal from 'components/StudentLoanForgiveness/Modal/AreYouSureModal';
import { ReactComponent as ProfileIcon } from 'images/profile.svg';
import { ReactComponent as LockIcon } from 'images/lock.svg';
import { ReactComponent as MoneyIcon } from 'images/money-purple.svg';
import { ReactComponent as Contract } from 'images/contract.svg';
import { addMonths } from 'date-fns';
import Expander from 'components/Expander/Expander';
import { getStudentLoanData } from 'selectors/getStudentLoanData';
import { formatMonetaryAmount } from 'utils/formatMonetaryAmount';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import { setupStudentLoanPayment } from 'thunks';
import { RoutePath } from 'enums/Routes';

import styles from './Submit.module.scss';

const ADVISOR_COST_INSTALLMENTS = [330, 330, 330];

const Submit = ({ navigationInfo, handleNext }: FlowComponentType) => {
  const [areYouSureModalOpen, setAreYouSureModalOpen] = useState(false);
  const [isSetupPaymentStarted, setIsSetupPaymentStarted] = useState(false);

  const { applicationId, currentPath, recommendedPath } = useSelector(getStudentLoanData);

  const dispatchWithUnwrap = useDispatchWithUnwrap();

  const advisorCost = ADVISOR_COST_INSTALLMENTS.reduce((total, value) => total + value, 0);
  const currentPathCost = currentPath?.totalPayment ?? 0;
  const recommendedPathCost = (recommendedPath?.totalPayment ?? 0) + advisorCost;
  const savings = currentPathCost - recommendedPathCost;

  const startDate = new Date();

  const chartData = React.useMemo(() => getChartData(startDate, currentPath?.payments, recommendedPath?.payments), []);
  const primaryAxis = React.useMemo(
    (): AxisOptions<DataPoint> => ({
      getValue: (datum) => datum.date,
      hardMin: startDate,
      min: startDate,
      padBandRange: false,
      formatters: {
        cursor: (value: Date) =>
          value &&
          value.toLocaleDateString('en-US', {
            month: 'short',
            year: 'numeric',
          }),
        tooltip: (value: Date) =>
          value &&
          value.toLocaleDateString('en-US', {
            month: 'short',
            year: 'numeric',
          }),
      },
    }),
    [],
  );
  const secondaryAxes = React.useMemo(
    (): AxisOptions<DataPoint>[] => [
      {
        getValue: (datum) => datum.amount,
        formatters: {
          scale: (value: number) => formatMonetaryAmount(value),
        },
      },
    ],
    [],
  );

  const onNext = async () => {
    if (isSetupPaymentStarted) {
      return;
    }
    analytics.track('Student Loan Setup Payment Link Opened');
    setAreYouSureModalOpen(false);
    setIsSetupPaymentStarted(true);

    const setupResult = await dispatchWithUnwrap(
      setupStudentLoanPayment({
        applicationId: applicationId!,
        returnUrl: `${window.location.origin}${RoutePath.StudentLoanApplyPaymentResult}`,
      }),
    );
    window.location.replace(setupResult.checkoutUrl);
  };

  const handleNotNow = () => {
    analytics.track('Schedule Consultation Not Now Pressed');
    handleNext(StudentLoanBookAppointmentResult.NotNow);
  };

  return (
    <>
      {areYouSureModalOpen && (
        <AreYouSureModal
          onClose={() => setAreYouSureModalOpen(false)}
          onNext={() => setAreYouSureModalOpen(false)}
          onNotNow={handleNotNow}
          nextLabel="Continue"
        />
      )}
      <FormNavigation {...navigationInfo} />
      <FormContainer
        icon={<Contract />}
        title="Submit Your Application"
        subtitle="The last step is to pay our advisors so they can get to work."
      >
        <div className={styles.advantages}>
          <div className={styles.advantage}>
            <ProfileIcon className={styles.icon} />
            <p className={styles.advantageLabel}>
              This fee covers all of the work to consolidate your loans, select and apply for the assistance programs,
              appeal decisions, and any coordination necessary with you and your employer.
            </p>
          </div>
          <div className={styles.advantage}>
            <LockIcon className={styles.icon} />
            <p className={styles.advantageLabel}>We guarantee approval or we will refund the full amount.</p>
          </div>
          <div className={styles.advantage}>
            <MoneyIcon className={styles.icon} />
            <p className={styles.advantageLabel}>
              We recommended this option because we think it’ll save you the most money.
            </p>
          </div>
        </div>

        {savings > 0 && (
          <Expander
            label={
              <div className={styles.row}>
                <div className={styles.label}>Estimated Net Savings:</div>
                <div className={styles.value}>{formatMonetaryAmount(savings)}</div>
              </div>
            }
            content={
              <div className={styles.savingsDetail}>
                <div className={styles.legend}>
                  <div className={clsx(styles.item, styles.highlighted)}>
                    <div className={styles.bullet} />
                    <div className={styles.content}>
                      <div className={styles.title}>Updated Path including fees</div>
                      <div className={styles.value}>({formatMonetaryAmount(recommendedPathCost)} Total Payment)</div>
                    </div>
                  </div>
                  <div className={styles.item}>
                    <div className={styles.bullet} />
                    <div className={styles.content}>
                      <div className={styles.title}>Original Path</div>
                      <div className={styles.value}>({formatMonetaryAmount(currentPathCost)} Total Payment)</div>
                    </div>
                  </div>
                </div>
                <div className={styles.chartWrapper}>
                  <Chart
                    options={{
                      data: chartData,
                      primaryAxis,
                      secondaryAxes,
                      defaultColors: ['#795AF7' /* purple500 */, '#C5CBD4' /* black20 */],
                    }}
                  />
                </div>
              </div>
            }
            labelClassname={styles.expanderTitle}
            container={styles.expanderContainer}
            openClassname={styles.expanderOpen}
          />
        )}

        <div className={styles.feeBreakdown}>
          <h2>One-time service fee:</h2>
          <div className={clsx(styles.feeRow, styles.heading)}>
            <div className={styles.label}>Due Today:</div>
            <div className={styles.value}>{formatMonetaryAmount(ADVISOR_COST_INSTALLMENTS[0])}</div>
          </div>
          {ADVISOR_COST_INSTALLMENTS.slice(1).map((value, month) => (
            <div className={styles.feeRow}>
              <div className={styles.label}>
                Due on{' '}
                {addMonths(startDate, month + 1).toLocaleDateString('en-US', {
                  month: 'numeric',
                  day: '2-digit',
                })}
                :
              </div>
              <div className={styles.value}>{formatMonetaryAmount(value)}</div>
            </div>
          ))}
        </div>

        <div className={styles.buttonContainer}>
          <Button onClick={onNext} isLoading={isSetupPaymentStarted}>
            Checkout
          </Button>

          <Button type={ButtonType.Transparent} onClick={() => setAreYouSureModalOpen(true)}>
            Not Now
          </Button>
        </div>
      </FormContainer>
    </>
  );
};

interface DataPoint {
  date: Date;
  amount: number;
}

const getChartData = (
  startDate: Date,
  currentPathPayments?: number[],
  recommendedPathPayments?: number[],
): { label: string; data: DataPoint[] }[] => {
  const currentPayments = [...(currentPathPayments ?? [])];
  const recommendedPayments = [...(recommendedPathPayments ?? [])];

  ADVISOR_COST_INSTALLMENTS.forEach((amount, index) => {
    if (index === recommendedPayments.length) {
      recommendedPayments.push(0);
    }
    recommendedPayments[index] += amount;
  });

  while (recommendedPayments.length < currentPayments.length) {
    recommendedPayments.push(0);
  }

  return [
    {
      label: 'Recommended Path',
      data: convertToCumulativeSeries(startDate, recommendedPayments),
    },
    {
      label: 'Current Path',
      data: convertToCumulativeSeries(startDate, currentPayments),
    },
  ];
};

const convertToCumulativeSeries = (startDate: Date, payments: number[]): DataPoint[] => {
  let totalPayment = 0;
  return payments.map((payment, index) => {
    totalPayment += payment;
    return { date: addMonths(startDate, index), amount: totalPayment };
  });
};

export default Submit;
