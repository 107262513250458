import { NavigateOptions } from 'react-router-dom';

import { KnownEmployerProfitStatus } from 'api/StudentLoanApi';

import EmployerType from 'components/StudentLoanForgiveness/Questions/EmployerType';
import NonProfitHireDate from 'components/StudentLoanForgiveness/Questions/NonProfitHireDate';
import PslfAwareness from 'components/StudentLoanForgiveness/Questions/PslfAwareness';
import PslfNotEnrolled from 'components/StudentLoanForgiveness/Questions/PslfNotEnrolled';
import StudentLoanSavings from 'components/StudentLoanForgiveness/StudentLoanSavings/StudentLoanSavings';
import BookAdvisor from 'components/StudentLoanForgiveness/WereHereToHelp/BookAdvisor';
import BookAppointment from 'components/StudentLoanForgiveness/BookAppointment/BookAppointment';
import ScheduleDetails from 'components/StudentLoanForgiveness/ScheduleDetails/ScheduleDetails';
import SaveAwareness from 'components/StudentLoanForgiveness/Questions/SaveAwareness';
import SaveNotEnrolled from 'components/StudentLoanForgiveness/Questions/SaveNotEnrolled';
import PslfWontSaveMoneyDetails from 'components/StudentLoanForgiveness/Questions/PslfWontSaveMoneyDetails';
import ApplyOptions from 'components/StudentLoanForgiveness/Apply/ApplyOptions';
import ApplyWithUs from 'components/StudentLoanForgiveness/Apply/ApplyWithUs';
import ApplyOnYourOwn from 'components/StudentLoanForgiveness/Apply/ApplyOnYourOwn';
import { ApplyNavigation } from 'components/StudentLoanForgiveness/Apply/applySteps';
import PaymentResult from 'components/StudentLoanForgiveness/Apply/PaymentResult';
import Submit from 'components/StudentLoanForgiveness/Apply/Submit';

import { RoutePath } from 'enums/Routes';
import {
  StudentLoanEmployerResult,
  StudentLoanForgivenessAwarenessResult,
  StudentLoanNotEnrolledResult,
  StudentLoanBookAdvisorResult,
  StudentLoanBookAppointmentResult,
  StudentLoanSavingsResult,
  StudentLoanSaveRepaymentPlanResult,
  StudentLoanPslfWontSaveMoneyResult,
  StudentLoanApplyOptionsResult,
  StudentLoanApplyOnYourOwnResult,
  StudentLoanPayingResult,
} from 'enums/StudentLoanForgivenessFlowResults';
import { RootState } from 'handlers';
import { EmploymentDetailsResult, StepsResult } from 'enums/FlowNextResults';
import { StudentLoanDataState } from 'handlers/studentLoanData';

import { RouterType } from './FlowRouter';

export type NextFunction = (state: RootState, navigate: (to: RoutePath, options?: NavigateOptions) => void) => void;

const title = 'Student Loan Forgiveness';

export const getStudentLoanForgivenessRoutes = (next: NextFunction): RouterType => ({
  [RoutePath.StudentLoanEmployerType]: {
    navigationInfo: { showBackLink: false, title },
    component: EmployerType,
    handleNext: ({ navigate }) => (result) => {
      switch (result) {
        case StudentLoanEmployerResult.NonProfit:
          navigate(RoutePath.StudentLoanNonProfitHireDate);
          break;
        case StudentLoanEmployerResult.ForProfit:
          navigate(RoutePath.StudentLoanSaveRepaymentPlan);
          break;
        case StudentLoanEmployerResult.NotSure:
          navigate(RoutePath.StudentLoanForgivenessAwareness);
          break;
        default:
          navigate(RoutePath.Error);
          break;
      }
    },
  },
  [RoutePath.StudentLoanNonProfitHireDate]: {
    navigationInfo: { showBackLink: true, title },
    component: NonProfitHireDate,
    handleNext: ({ navigate }) => () => {
      navigate(RoutePath.StudentLoanForgivenessAwareness);
    },
  },
  [RoutePath.StudentLoanSaveRepaymentPlan]: {
    navigationInfo: { showBackLink: true, title },
    component: SaveAwareness,
    handleNext: ({ navigate }) => (result) => {
      switch (result) {
        case StudentLoanSaveRepaymentPlanResult.Enrolled:
          navigate(RoutePath.StudentLoanBookAdvisor);
          break;
        case StudentLoanSaveRepaymentPlanResult.NotEnrolled:
          navigate(RoutePath.StudentLoanWhyNotEnrolled);
          break;
        case StudentLoanSaveRepaymentPlanResult.HaveNotHeard:
          navigate(RoutePath.StudentLoanSavings);
          break;
        default:
          navigate(RoutePath.Error);
          break;
      }
    },
  },
  [RoutePath.StudentLoanForgivenessAwareness]: {
    navigationInfo: { showBackLink: true, title },
    component: PslfAwareness,
    handleNext: ({ navigate }) => (result) => {
      switch (result) {
        case StudentLoanForgivenessAwarenessResult.Yes:
          navigate(RoutePath.StudentLoanNotEnrolled);
          break;
        case StudentLoanForgivenessAwarenessResult.No:
          navigate(RoutePath.StudentLoanSavings);
          break;
        default:
          break;
      }
    },
  },
  [RoutePath.StudentLoanNotEnrolled]: {
    navigationInfo: { showBackLink: true, title },
    component: PslfNotEnrolled,
    handleNext: ({ navigate }) => (result) => {
      switch (result) {
        case StudentLoanNotEnrolledResult.Applying:
          navigate(RoutePath.StudentLoanBookAdvisor);
          break;
        case StudentLoanNotEnrolledResult.WontApprove:
        case StudentLoanNotEnrolledResult.DifficultToApply:
          navigate(RoutePath.StudentLoanSavings);
          break;
        case StudentLoanNotEnrolledResult.WontSaveMoney:
          navigate(RoutePath.StudentLoanPslfWontSave);
          break;
        default:
          navigate(RoutePath.Error);
      }
    },
  },
  [RoutePath.StudentLoanPslfWontSave]: {
    navigationInfo: { showBackLink: true, title },
    component: PslfWontSaveMoneyDetails,
    handleNext: ({ navigate }) => (result) => {
      switch (result) {
        case StudentLoanPslfWontSaveMoneyResult.IsStudent:
          navigate(RoutePath.StudentLoanBookAdvisor);
          break;
        case StudentLoanPslfWontSaveMoneyResult.Other:
          navigate(RoutePath.StudentLoanSavings);
          break;
        default:
          navigate(RoutePath.Error);
      }
    },
  },
  [RoutePath.StudentLoanWhyNotEnrolled]: {
    navigationInfo: { showBackLink: true, title },
    component: SaveNotEnrolled,
    handleNext: ({ navigate }) => () => {
      navigate(RoutePath.StudentLoanSavings);
    },
  },
  [RoutePath.StudentLoanSavings]: {
    navigationInfo: { showBackLink: true, title },
    component: StudentLoanSavings,
    handleNext: ({ navigate, state }) => (result) => {
      switch (result) {
        case StudentLoanSavingsResult.Next:
          navigate(RoutePath.StudentLoanApplyOptions);
          break;
        case StudentLoanSavingsResult.NotNow:
          next(state, navigate);
          break;
        default:
          navigate(RoutePath.Error);
          break;
      }
    },
  },
  [RoutePath.StudentLoanBookAppointment]: {
    navigationInfo: { showBackLink: true, title },
    component: BookAppointment,
    handleNext: ({ state, navigate }) => (result) => {
      switch (result) {
        case StudentLoanBookAppointmentResult.Schedule:
          navigate(RoutePath.StudentLoanScheduleDetails);
          break;
        case StudentLoanBookAppointmentResult.NotNow:
          next(state, navigate);
          break;
        default:
          navigate(RoutePath.Error);
          break;
      }
    },
  },
  [RoutePath.StudentLoanBookAdvisor]: {
    navigationInfo: { showBackLink: true, title },
    component: BookAdvisor,
    handleNext: ({ navigate, state }) => (result) => {
      switch (result) {
        case StudentLoanBookAdvisorResult.Schedule:
          navigate(RoutePath.StudentLoanScheduleDetails);
          break;
        case StudentLoanBookAdvisorResult.NotNow:
          next(state, navigate);
          break;
        default:
          navigate(RoutePath.Error);
          break;
      }
    },
  },
  [RoutePath.StudentLoanScheduleDetails]: {
    navigationInfo: { showBackLink: false, title },
    component: ScheduleDetails,
    handleNext: ({ navigate, state }) => () => {
      navigate(RoutePath.StudentLoanScheduleDetails);
      next(state, navigate);
    },
  },
  [RoutePath.StudentLoanApplyOptions]: {
    navigationInfo: { showBackLink: true, title },
    component: ApplyOptions,
    handleNext: ({ state, navigate }) => (result) => {
      switch (result) {
        case StudentLoanApplyOptionsResult.ApplyWithUs:
          navigate(RoutePath.StudentLoanApplyWithUs);
          break;
        case StudentLoanApplyOptionsResult.ApplyOnYourOwn:
          navigate(RoutePath.StudentLoanApplyOnYourOwn);
          break;
        case StudentLoanApplyOptionsResult.Schedule:
          navigate(RoutePath.StudentLoanScheduleDetails);
          break;
        case StudentLoanApplyOptionsResult.NotNow:
          next(state, navigate);
          break;
        default:
          navigate(RoutePath.Error);
          break;
      }
    },
  },
  [RoutePath.StudentLoanApplyWithUs]: {
    navigationInfo: { showBackLink: true, title },
    component: ApplyWithUs,
    handleNext: ({ navigate }) => () => {
      navigate(RoutePath.StudentLoanApplySteps);
    },
  },
  [RoutePath.StudentLoanApplyOnYourOwn]: {
    navigationInfo: { showBackLink: true, title },
    component: ApplyOnYourOwn,
    handleNext: ({ state, navigate }) => (result) => {
      switch (result) {
        case StudentLoanApplyOnYourOwnResult.ApplyWithUs:
          navigate(RoutePath.StudentLoanApplyWithUs);
          break;
        case StudentLoanApplyOnYourOwnResult.Done:
          next(state, navigate);
          break;
        default:
          navigate(RoutePath.Error);
          break;
      }
    },
  },
  [RoutePath.StudentLoanApplySteps]: {
    component: ApplyNavigation,
    handleNext: ({ navigate }) => async (result) => {
      switch (result) {
        case StepsResult.Completed:
          navigate(RoutePath.StudentLoanApplySubmit);
          break;
        case EmploymentDetailsResult.Continue:
          break;
        case EmploymentDetailsResult.Rejected:
        case StepsResult.Rejected:
          navigate(RoutePath.OfferStatus);
          break;
        default:
          navigate(RoutePath.Error);
          break;
      }
    },
  },
  [RoutePath.StudentLoanApplySubmit]: {
    navigationInfo: { showBackLink: true, title },
    component: Submit,
    handleNext: ({ navigate }) => () => {
      navigate(RoutePath.Error);
    },
  },
  [RoutePath.StudentLoanApplyPaymentResult]: {
    navigationInfo: { showBackLink: true, title },
    component: PaymentResult,
    handleNext: ({ state, navigate }) => (result) => {
      if (result === StudentLoanPayingResult.Success) {
        next(state, navigate);
      } else {
        navigate(RoutePath.StudentLoanApplySubmit);
      }
    },
  },
});

export const navigateToStudentLoanStart = (
  navigate: (to: RoutePath, options?: NavigateOptions) => void,
  studentLoanData: StudentLoanDataState,
) => {
  switch (studentLoanData.knownEmployerProfitStatus) {
    case KnownEmployerProfitStatus.NonProfit:
      navigate(RoutePath.StudentLoanNonProfitHireDate);
      break;
    case KnownEmployerProfitStatus.ForProfit:
      navigate(RoutePath.StudentLoanSaveRepaymentPlan);
      break;
    default:
      navigate(RoutePath.StudentLoanEmployerType);
      break;
  }
};
